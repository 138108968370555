<!--
 * @Author: zhangsheng zhangsheng@whty.com.cn
 * @Date: 2022-12-01 14:34:50
 * @LastEditors: zhangsheng zhangsheng@whty.com.cn
 * @LastEditTime: 2023-01-04 10:01:28
 * @FilePath: \web\src\views\invoicing\batchElectronicInvoice\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="app-container">
        <ul>
            <li v-for="item in invoiceType" :key='item.id' :class="item.class" @click="toPath(item)" v-auth='item.auth'>
                <i class="iconfont iconicon2-24"></i>
                <p class="text">{{item.title}}</p>
                <p class="toAdd">点击进入</p>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name: "addIncome",
    data() {
      return {
         invoiceType: [
            {id:'1002',title:'增值税电子普通发票',class:'blue',path:'ordinary',type:'income',auth:"electronicInvoice:normal"},
            {id:'1003',title:'增值税电子专用发票',class:'green',path:'specialUse',type:'income',auth:"electronicInvoice:special"},
         ],
      }
    },
    methods: {
        toPath(item){
            if(this.getToken('hasJoinCompany') == 'false'){
                einvAlert.warning('提示','当前公司尚未通过开票接入申请,无法使用该模块','确定','我知道了')
                return false;
            }
            this.$router.push({
                path: "/invoicing/electronicInvoice/" + item.path,
                // query: {type: item.path}
            })
        }
    }
}
</script>
<style lang="less" scoped>
    .app-container{
        background: #FFF;
        height: 92% !important;;
    }
    ul{
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        padding: 0 30px;
        li{
            width: 25.7rem;
            height: 180px;
            margin-top: 28px;
            padding-left: 40px;
            margin-right: 32px;
            list-style: none;
            color: #FFF;
            cursor: pointer;
            .iconicon2-24{
                display: block;
                font-size: 38px;
                margin-top: 24px;
                margin-bottom: 18px;
            }
            .text{
                font-size: 24px;
                margin: 0;
            }
            .toAdd{
                font-size: 18px;
                margin: 16px 0 0;
            }
        }
        li:nth-child(3n){
            margin-right: 0px;
        }
        .violet{
            background: url("~@/assets/image/violet.webp") no-repeat;
            background-size: 100% 100%;
        }
        .blue{
            background: url("~@/assets/image/blue.webp") no-repeat;
            background-size: 100% 100%;
        }
        .green{
            background: url("~@/assets/image/green.webp") no-repeat;
            background-size: 100% 100%;
        }
        .orange{
            background: url("~@/assets/image/orange.webp") no-repeat;
            background-size: 100% 100%;
        }
    }
</style>